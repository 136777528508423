import React from 'react';
import './app.css';
import angelfish from './img/sa/angelfish.png';
import nebula from './img/sa/nebula.png';
import triSpeeder from './img/sa/tri-speeder.png';
//import hangar from './img/sa/spaces-aces-hangar.mp4';
//import acesAction from './img/sa/spaces-aces-action.mp4';
import apShort from './img/sa/ap-short.mp4';
import acesTitle from './img/sa/spaces-aces-title.mp4';
import dayNight from './img/experimental-day-night.mp4';
import p38 from './img/one/p38-animated.mp4';


class Spotlight extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            i: 0, 
            pictures: [
                <video playsInline={true} loading='lazy'  preload='auto' onEnded={()=>this.changePic()} autobuffer  autoPlay muted src={'https://cdn-animation.artstation.com/p/video_sources/000/858/539/spaces-aces-action.mp4'}    alt=''/>, 
                <video playsInline={true} loading='lazy'  preload='auto' onEnded={()=>this.changePic()} autobuffer  autoPlay muted src={'https://cdn-animation.artstation.com/p/video_sources/000/858/545/spaces-aces-hangar.mp4'}    alt='' />, 
                <img   src={triSpeeder}   onLoad={()=>this.timedChange()} loading='lazy' alt='Tri-Speeder spaceship model from Ace Perinova by Autumn (KookaNova).' />,
                <img   src={nebula}       onLoad={()=>this.timedChange()} loading='eager' alt='Nebula generated in Blender and rendered as a HDRI sky.' />, 
                <img   src={angelfish}    onLoad={()=>this.timedChange()} loading='lazy' alt="Angelfish spaceship model from Space's Aces Game by Autumn (KookaNova)." />, 
                <video playsInline={true} loading='eager' preload='auto' onEnded={()=>this.changePic()} autobuffer autoPlay muted src={apShort}      alt='Tri-Speeder destroying a test target in Ace Perinova by Autumn (KookaNova).'/>,
                <video playsInline={true} loading='eager' preload='auto' onEnded={()=>this.changePic()} autobuffer autoPlay muted src={dayNight}     alt='' />, 
                
            ],
            title: [
                "Spawn-in from Space's Aces",
                "Tri-Speeder in Hangar from Space's Aces",
                "Tri-Speeder model from Ace Perinova",
                "Nebula generated in Blender",
                "Angelfish from Space's Aces",
                "Action shot from Ace Perinova",
                "Experimental Volumes and Day-Night",
                
                
                
            ],
            description: [
                "Programmed all gameplay for Space's Aces.",
                "Programmed character and ship systems, and select screens.",
                "Design, model, and texture. High fidelity.", 
                "Generated as a volume, rendered as a HDRI for skyboxes.",
                "Design, model, and texture.",
                "HUD, Radar, Gameplay programming plus FX.",
                "Project used to teach UI Toolkit, used it to experiment with volumes and day-night.",
                 
                
                
            ],
        };

    }

    timedChange(){
        this.timerID = setInterval(() => this.changePic(), 5000);
    }

    changePic(){
        clearInterval(this.timerID);
        if(this.state.i >= this.state.pictures.length - 1){
            this.setState({i: 0});
        }
        else{
            this.setState({i: this.state.i+1});
            
        }
    }

    render(){
        return(
            <div key={this.state.i} className='spotlight-item'>
                {this.state.pictures[this.state.i]}
                <div key={this.state.i} className='image-text'>
                    <h3>{this.state.title[this.state.i]}</h3>
                    <h4>{this.state.description[this.state.i]}</h4>
                </div>
            </div>
        );
    }
}

export default Spotlight;