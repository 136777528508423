import NavBar from './navBar';
import Footer from './footer';
import Card from './card';
import Spotlight from './spotlight';
import EmailSection from './emailSection';

let colors = {
    blue: '#10d0ff',
    green: '#30e530',
    red: '#ff6080',
    yellow: '#f8e914'
}

function AboutSection(){
    return(
        <div style={{borderColor: colors.red}} className='panel' id='#about'>
            <h2>A little about <span>myself.</span></h2>
            <p >I'm Autumn, a technology enthusiast and game developer. I've been teaching technology for a multitude of years now, 
                filling various mentor roles and teaching topics ranging from computer science and database scraping, all the way to electron flow in circuitry.
                Have you ever wondered how you computer knows how long a second is? How does human written code translate down to ones and zeroes in yo computer's RAM?
                These kinds of questions fuel my fascination with computer science and engineering and is the driving force behind my desire to keep learning and experimenting with this
                pervasive technology that has forever changed the course of human evolution. The answers are quartz crystals and assembly by the way.
                With a Bachelor of Science in Computer Game Development, I've dedicated years to creating well written code, optimized and appropriately abstracted, and geared towards 
                supporting artists in creating captivating experiences. On my own, I read about chemistry, electronics, and try my hand at inventing. <br/>
                For inquiries I can be reached at <a href='mailto: autumnin3d@gmail.com'>autumnin3d@gmail.com</a>.
            </p>
        </div>
    );
}

function SkillsSection(){
    return(
        <div className='section' id='skills'>
            <h1>Main Skills</h1>
            <div className='horizontal-scroll'>
                <Card color={colors.blue} title='Programming' item={
                    <ul>
                        <li>C# and general C language kills with a focus on creating strong, modifiable, and <i style={{color: colors.blue}}>organized</i> systems geared towards helping artists make experiences.</li>
                        <li>Strong understanding of Unity and their modernized packages including the <i style={{color: colors.blue}}>Input System Package, Shader and FX Graph, and UI Toolkit and Builder.</i></li>
                        <li>Experience with UI Toolkit creating <i style={{color: colors.blue}}>Unity Editor tools</i> to optimize the game dev pipeline.</li>
                        <li>Created multiplayer using <i style={{color: colors.blue}}>Photon PUN.</i> Understanding of Remote Procedure Calls and packing complex data into hashtables and bytes for network delivery. Base C skills are helpful here.</li>
                    </ul>
                }/>
                <Card color={colors.green} title='3D Hard Surface / Environment' item={
                    <ul>
                        <li>Specialized in making <i style={{color: colors.green}}>beautiful</i> hard surface models and textures, primarily in Blender and textured with Substance painter.</li>
                        <li>Use of proper edge flow and different types of edge flows allows to create <i style={{color: colors.green}}>poly-scalable models</i> for realtime rendering.</li>
                        <li><i style={{color: colors.green}}>4 years of Maya</i> training and experience plus multiple years of Blender3D, with a focus on real time rendering in Unity.</li>
                        <li>Trained and worked with <i style={{color: colors.green}}>industry professionals</i> from Trideum Corporation, EA, and worked for on Contract an artist at Bungie.</li>
                    </ul>
                }/>
                <Card color={colors.red} title='Materials and Shaders' item={
                    <ul>
                        <li>Strong skill using <i style={{color: colors.red}}>Substance Designer</i> for creating materials, both natural and artificial.</li>
                        <li>Created a template that <i style={{color: colors.red}}>converts</i> Substance Designer maps to Unity URP and HDRP compatible maps. Designer does not have these conversions by default.</li>
                        <li>Created <i style={{color: colors.red}}>shaders</i> instead of materials to add effects based off of model properties to <i style={{color: colors.red}}>optimize</i> material and object loading. Ex. Blinking lights shader that uses vertex colors for to determine shader properties. Ex. Hue = color, saturation = blink rate, and value = glow amount.</li>
                        <li>Techniques implemented to reduce unnecessary noise and complexity to maps when desired. This lowers the file size without necessarily reducing resolution.</li>
                        <li>Understanding of what makes a material <i style={{color: colors.red}}>realistic vs. stylistic.</i> A good material artist should know when to control the detail, or how to make a material look hand crafted.</li>
                    </ul>
                }/>

            </div>
        </div>
    );
}

export default function App(){
    return(
        <div>
            <NavBar />
            <Spotlight />
            <AboutSection />
            <SkillsSection />
            <EmailSection />
            <Footer />
        </div>
    );
}