import React from 'react';import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './app';
import GameDev from './gameDev';
import Modeling from './modeling';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<App />} />
            <Route path='game-dev' element={<GameDev />} />
            <Route path='3d-art-modeling' element={<Modeling />} />
        </Routes>
    </BrowserRouter>
);