import React from 'react';
import NavBar from './navBar.js';
import Footer from './footer.js';
import Card from './card.js';
//Spaces Aces
import saMenu       from './img/sa/spaces-aces-menu.jpg';
import saSystem     from './img/sa/spaces-aces-character-system.png';
import afCockpit    from './img/sa/angelfish-cockpit.PNG';
import tsCockpit    from './img/sa/spaces-aces-tri-speeder-cockpit.png';
import vespid       from './img/sa/vespid.png';
import spawnShip    from './img/sa/spawn-ship.png';
import dragonfly    from './img/sa/megani-dragonfly.png';
//X-hax
import xhEnv        from './img/xh/environment-concept.png';
import xhGame0      from './img/xh/gameplay-0.jpg';
import xhGame1      from './img/xh/gameplay-1.jpg';
import xhGame2      from './img/xh/gameplay-2.jpg';
import xhDesign     from './img/xh/level-design.jpg';
import xhLayout     from './img/xh/level-layouts.png';
import xhPrinter    from './img/xh/printer-concept.jpg';
//ATT
import attAction0   from './img/att/att-action-0.png';
import attAction1   from './img/att/att-action-1.png';
import attAction2   from './img/att/att-action-2.png';
import attHampster  from './img/att/connor-cox-hamstersprites.jpg';
import attPlatforms from './img/att/connor-cox-platforms-and-pipes.jpg';
import attEnemies   from './img/att/connor-cox-rolling-enemies.jpg';
import attLayout    from './img/att/connor-cox-stage-layoutpaper.jpg';
//ones
import apThumb      from './img/sa/ap-thumb.png';
import ataxx        from './img/one/ataxx-logo.png';
import rover        from './img/one/rover.png';
import pilots       from './img/one/over-mountains.gif';


let colors = {
    blue: '#10d0ff',
    green: '#30e530',
    red: '#ff6080',
    yellow: '#f8e914'
}

function GamesDeveloped(){
    return (
        <div className='section'>
            <h1>Complete Projects</h1>
            <Card color={colors.green} title="Space's Aces" item={
                <div>
                    <div className='horizontal'>
                        <a className='picture-link' target='_blank' rel="noreferrer" href='https://www.artstation.com/artwork/OmVaOb'><img className='thumbnail' src='https://cdnb.artstation.com/p/assets/covers/images/051/392/693/small_square/connor-cox-connor-cox-spaces-aces-icon.jpg'/></a>
                        <div>
                            <h3>Contributions</h3>
                            <ul>
                                <li>Game Designer, C# Developer.</li>
                                <li>Environment and Material Artist.</li>
                                <li>Modeled and Designed multiple Ships.</li>
                                <li>Wrote shaders for models.</li>
                                <li>Music and Sound.</li>
                                <li>Directed and managed the team.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 style={{ color: colors.green }}>Description</h3>
                            <p>Space's Aces Game is a single player spaceship fighter. Pick a ship with unique weapons and stats,
                                and face off with your team against opponents to score the most. To win, you must out maneuver your opponents
                                like a true ace.<br /><br /></p>
                            <a target='_blank' rel="noreferrer" href='https://www.artstation.com/artwork/OmVaOb'>Check the Art Station project page for more info.<br /><br /></a>
                            <a target='_blank' rel="noreferrer" href='https://kookanova.itch.io/spaces-aces'>Space's Aces on Itch.io</a>
                        </div>
                    </div>
                    <div style={{ height: '15vh', backgroundColor: '#000000' }} className='horizontal-scroll'>
                        <a href={saMenu}    target='_blank' rel="noreferrer"><img className='thumbnail' src={saMenu}     alt="Menu screen from Space's Aces showing asteroids and the Spawnship in the background."/></a>
                        <a href={saSystem}  target='_blank' rel="noreferrer"><img className='thumbnail' src={saSystem}   alt="Inspector view of the Character creation tool made for developers of Space's Aces. The same tool is used for ships."/></a>
                        <a href={afCockpit} target='_blank' rel="noreferrer"><img className='thumbnail' src={afCockpit}  alt="The cockpit of the Angelfish ship from Space's Aces, designed and modeled by Autumn Cox."/></a>
                        <a href={tsCockpit} target='_blank' rel="noreferrer"><img className='thumbnail' src={tsCockpit}  alt="The cockpit of the Tri-Speeder ship from Space's Aces, designed and modeled by Autumn Cox."/></a>
                        <a href={vespid}    target='_blank' rel="noreferrer"><img className='thumbnail' src={vespid}     alt="Concept art of the Vespid ship from Space's Aces, designed by Autumn Cox."/></a>
                        <a href={spawnShip} target='_blank' rel="noreferrer"><img className='thumbnail' src={spawnShip}  alt="Concept art of the Spawn Ship from Space's Aces, designed by Autumn Cox."/></a>
                        <a href={dragonfly} target='_blank' rel="noreferrer"><img className='thumbnail' src={dragonfly}  alt="Concept art of the Dragonfly ship from Space's Aces, designed by Autumn Cox."/></a>
                    </div>
                </div>
            } />
            <Card color={colors.blue} title="X-Hacker Sister Project" item={
                <div>
                    <div className='horizontal'>
                    <a className='picture-link' target='_blank' rel="noreferrer" href='https://www.artstation.com/artwork/KOEwPx'><img className='thumbnail' src='https://cdnb.artstation.com/p/assets/covers/images/046/242/951/small_square/connor-cox-connor-cox-screenshot-2022-02-11-4-29-24-pm.jpg' /></a>
                        <div>
                            <h3>Contributions</h3>
                            <ul>
                                <li>Managed Tasking for Team.</li>
                                <li>Presented progress to investors.</li>
                                <li>Level Designer and Artist.</li>
                                <li>Main C# Developer.</li>
                                <li>Gameplay Designer.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 style={{ color: colors.blue }}>Description</h3>
                            <p>This project was intended for Trideum to use as a showcase for serious games aimed at a younger demographic.
                                I pitched the concept, managed the team, and did the programming. I had the opportunity to work with the amazing
                                Kevin Webb when working on this project. The majority of my role was on creating the systems for dialogue
                                and movement, and designing the mini-games to properly teach the concepts for each level.The art style, characters,
                                and 2D art were done by the brilliant Sierra Marsh. This project was handed over to Trideum in April 2021.<br /><br /> </p>
                            <a target='_blank' href='https://www.artstation.com/artwork/KOEwPx'>Check the Art Station project page for more info.<br /><br /></a>
                        </div>
                    </div>
                    <div style={{ height: '15vh', backgroundColor: '#000000' }} className='horizontal-scroll'>
                        <a href={xhEnv}     target='_blank' rel="noreferrer"><img className='thumbnail' src={xhEnv}     alt=""/></a>
                        <a href={xhDesign}  target='_blank' rel="noreferrer"><img className='thumbnail' src={xhDesign}  alt=""/></a>
                        <a href={xhGame0}   target='_blank' rel="noreferrer"><img className='thumbnail' src={xhGame0}   alt=""/></a>
                        <a href={xhGame1}   target='_blank' rel="noreferrer"><img className='thumbnail' src={xhGame1}   alt=""/></a>
                        <a href={xhGame2}   target='_blank' rel="noreferrer"><img className='thumbnail' src={xhGame2}   alt=""/></a>
                        <a href={xhLayout}  target='_blank' rel="noreferrer"><img className='thumbnail' src={xhLayout}  alt=""/></a>
                        <a href={xhPrinter} target='_blank' rel="noreferrer"><img className='thumbnail' src={xhPrinter} alt=""/></a>
                    </div>
                </div>
            } />
            <Card color={colors.red} title="Alfredo's Tiny Travels" item={
                <div>
                    <div className='horizontal'>
                    <a className='picture-link' target='_blank' rel="noreferrer" href='https://www.artstation.com/artwork/W2r8NN'><img className='thumbnail' src='https://cdna.artstation.com/p/assets/videos/images/017/686/136/20190504032341/small_square/connor-cox-hqdefault.jpg' /></a>
                        <div>
                            <h3>Contributions</h3>
                            <ul>
                                <li>First time in Unity!</li>
                                <li>Animated almost entirely on paper.</li>
                                <li>Learned C# and Unity.</li>
                                <li>Used events to make components that connect into complex systems.</li>
                                <li>Provided everything, no external assets were used.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 style={{ color: colors.red }}>Description</h3>
                            <p>Every piece of art, music, sound, and code was created by myself for this game. This game was made in Unity and took about
                                3 months from scratch to having complete menus and a full level. Most of the art was made on paper and scanned into the computer, but
                                some was digital through either Clip Studio Paint or Illustrator. This was my first time making a game and it taught me that Unity is
                                a wonderful program. Looking back, game development as a whole changed the way I view everything and solve all problems. Years later,
                                I can now see how the course of my career has changed and how my thinking has evolved. At the time I was primarily an animator, and now
                                I teach students how to code. Code rewired my brain and I'm a better person for it 100%. I feel like I can do anything, and learn anything.<br /><br /></p>
                            <a target='_blank' href='https://www.artstation.com/artwork/W2r8NN'>Check the Art Station project page for more info.<br /><br /></a>
                        </div>
                    </div>
                    <div style={{ height: '15vh', backgroundColor: '#000000' }} className='horizontal-scroll'>
                        <a href={attAction0}    target='_blank' rel="noreferrer"><img className='thumbnail' src={attAction0}    alt=""/></a>
                        <a href={attAction1}    target='_blank' rel="noreferrer"><img className='thumbnail' src={attAction1}    alt=""/></a>
                        <a href={attAction2}    target='_blank' rel="noreferrer"><img className='thumbnail' src={attAction2}    alt=""/></a>
                        <a href={attEnemies}    target='_blank' rel="noreferrer"><img className='thumbnail' src={attEnemies}    alt=""/></a>
                        <a href={attHampster}   target='_blank' rel="noreferrer"><img className='thumbnail' src={attHampster}   alt=""/></a>
                        <a href={attLayout}     target='_blank' rel="noreferrer"><img className='thumbnail' src={attLayout}     alt=""/></a>
                        <a href={attPlatforms}  target='_blank' rel="noreferrer"><img className='thumbnail' src={attPlatforms}  alt=""/></a>
                    </div>
                </div>
            } />
        </div>
    );
}

function WorkInProgress() {
    return (
        <div style={{ borderColor: colors.yellow }} className='panel'>
            <h1>In Production</h1>
            <div style={{ scrollBehavior: 'smooth' }} className='horizontal'>
                <Card color={colors.blue} title={"Ace Perinova"} item={
                    <div className='horizontal'>
                        <a className='picture-link' target='_blank' href='https://youtu.be/JG60i3vx7uE?t=138'><img className='thumbnail' src={apThumb} alt=''/></a>
                        <div>
                            <h3>Description</h3>
                            <p>Multiplayer spaceship fighter, developed in Unity using Photon Fusion. Featured as "Best of Made with Unity". Keep up live as this project is being worked on with dev logs, tutorials,
                                 and posts about features on my Social platforms. Dev Logs coming soon! </p>
                        </div>

                    </div>
                } />
                <Card color={colors.red} title={"Deep Ataxx"} item={
                    <div className='horizontal'>
                        <a className='picture-link' href='https://twitter.com/KookaNova/status/1526339467337867264?s=20&t=280UU0vKDD5MvS3hd0b9EA' target='_blank' ><img className='thumbnail' src={ataxx} alt=''/></a>
                        <div>
                            <h3>Description</h3>
                            <p>Releasing on Itch and the Google Play store, experience a universe whose creators are trying to destroy it. Counter the spread of an infection 
                                atom destroying disease with your own time freezing disease and face off against the zealots of the plague. In production now.
                            </p>
                        </div>

                    </div>
                } />
            </div>

        </div>
    );
}

function ExperimentalProjects() {
    return (
        <div style={{ borderColor: colors.blue }} className='section'>
            <h1>Experiments</h1>
            <div style={{ scrollBehavior: 'smooth' }} className='horizontal-scroll'>
                <Card color={colors.blue} title={"A Rover's Tale"} item={
                    <div className='horizontal'>
                        <a href={rover} target='_blank' className='picture-link'><img className='thumbnail' src={rover} alt=''/></a>
                        <div>
                            <h3>Description</h3>
                            <p>This experimental project was made a tutorial project for Unity's UI Toolkit. As well, I've used it to experiment with Unity HDRP's volumes
                                and a day-night cycle. It will next be used to experiment with cave generation, and in the far future, weather and custom volume code.</p>
                        </div>

                    </div>
                } />
                <Card color={colors.green} title={"Popper Pilots"} item={
                    <div className='horizontal'>
                        <a href='#' target='_blank' className='picture-link'><img className='thumbnail' src={pilots} alt=''/></a>
                        <div>
                            <h3>Description</h3>
                            <p>Experimental project made with the purpose of generating an endless gameplay loop. The world would generate endlessly, as well would endless objectives
                                and collectibles. The purpose was to learn how to create a terrain generator.
                            </p>
                        </div>

                    </div>
                } />
                <Card color={colors.yellow} title={"Brodinjer"} item={
                    <div className='horizontal'>
                        <a href='https://soundcloud.com/kookanova/sets/brodinjer-official-soundtrack' target='_blank' className='picture-link'><img className='thumbnail' src='https://i1.sndcdn.com/artworks-FRjjG10rgfaHukYz-zofOPw-t500x500.jpg' alt=''/></a>
                        <div>
                            <h3>Description</h3>
                            <p>I was a part of this team, and contributed critique, play testing, and most importantly: music. I was the musician responsible for all the music in this 
                                fun little adventure. This was my first time contributing mostly just music to.
                            </p>
                        </div>

                    </div>
                } />
                <Card color={colors.green} title={"Super Pockets"} item={
                    <div className='horizontal'>
                        <a href='#' target='_blank' className='picture-link'><img className='thumbnail' src={ataxx} alt=''/></a>
                        <div>
                            <h3>Description</h3>
                            <p>To learn more about Unity's Physics, I created a game of Pool. You can get pretty decent phsyics out of Unity, but you have to edit a lot of values in 
                                the settings, and properly use physics materials. Someday, I'd like to give this a full treatment and release it.
                            </p>
                        </div>

                    </div>
                } />
                
            </div>

        </div>
    );
}


export default function GameDev() {
    return (
        <div>
            <NavBar />
            <GamesDeveloped />
            <WorkInProgress/>
            <ExperimentalProjects />
            <Footer />
        </div>
    );
}