export default function Card(props){
    const style = {
        color: props.color,
    }

    return(
        <div className='card' style={{borderColor: props.color}}>
            <h2 style={style}>{props.title}</h2>
            {props.item}
        </div>
    );
}