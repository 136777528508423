export default function EmailSection(){
    let yellow = '#f8e914';
    return(
        <div style={{borderColor: yellow}} className='panel' id='#mail-list'>
            <h2>Interested in my work?</h2>
            <form>
                <label>
                    Subscribe to my spam-less email list:
                    <input style={{marginLeft: '10px'}} type={'email'} enterKeyHint={'send'}></input>
                    <input type={'submit'} />
                </label>
            </form>
            <p>The content you will receive will be related to game releases, betas, tutorials, and maybe an occasional creator shoutout.</p>
            <p>That's it!</p>
        </div>
    );
}