import React from 'react';

function Footer(){
    return(
        <footer id="footer" className='footer-section'>
            <h2>Follow on Social Media</h2>
            <h4><span>@KookaNova</span></h4>
            <div className='social'>
                <a target='_blank' rel="me" href="https://twitter.com/KookaNova"><i className="fa-brands fa-twitter"></i></a>
                <a target='_blank' rel="me" href="https://www.youtube.com/kookanova"><i className="fa-brands fa-youtube"></i></a>
                <a target='_blank' rel="me" href="https://www.artstation.com/kookanova"><i className="fa-brands fa-artstation"></i></a>
                <a target='_blank' rel="me" href="https://www.linkedin.com/in/autumn-in-3d/"><i className="fa-brands fa-linkedin"></i></a>
                <a target='_blank' rel="me" href="https://mastodon.gamedev.place/@KookaNova"><i className="fa-brands fa-mastodon"></i></a>
                <a target='_blank' rel="me" href="https://kookanova.tumblr.com/"><i className="fa-brands fa-tumblr"></i></a>
            </div>
            <p>For hiring and business, contact <a href={'mailto:autumnin3d@gmail.com'}>AutumnIn3D@gmail.com</a></p>
            <p style={{paddingTop: '10vh'}} className='muted-text'>Created by Autumn Cox using React.js. All rights reserved.</p>
        </footer>
    );
}

export default Footer;
