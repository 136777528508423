import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavBar(){
    return(
        <nav id='nav'>
            <NavLink to='/'>
                <h1>Kooka<span>Nova</span></h1>
                <h4 style={{marginRight: '5vw'}}>Game Development by <span>Autumn Cox</span></h4>
            </NavLink>

            <ul>
                <li><NavLink to='/' className='navBar-link'>Home</NavLink></li>
                <li><NavLink to='/game-dev' className='navBar-link'>Game Development</NavLink></li>
                <li><NavLink to='/3d-art-modeling' className='navBar-link'>3D Art</NavLink></li>
            </ul>
            <div style={{display: 'flex', flexDirection: 'row' , alignItems: 'center', justifyContent: 'flex-end'}}>
                <a className='navBar-link' style={{paddingRight: '20%'}} href="#footer">Contact</a>
                <div className='social' style={{paddingRight: '10%'}}>
                    <a target='_blank' rel="me" href="https://twitter.com/KookaNova"><i className="fa-brands fa-twitter"></i></a>
                    <a target='_blank' rel="me" href="https://www.youtube.com/kookanova"><i className="fa-brands fa-youtube"></i></a>
                    <a target='_blank' rel="me" href="https://www.artstation.com/kookanova"><i className="fa-brands fa-artstation"></i></a>
                    <a target='_blank' rel="me" href="https://www.linkedin.com/in/autumn-in-3d/"><i className="fa-brands fa-linkedin"></i></a>
                    <a target='_blank' rel="me" href="https://mastodon.gamedev.place/@KookaNova"><i className="fa-brands fa-mastodon"></i></a>
                    <a target='_blank' rel="me" href="https://kookanova.tumblr.com/"><i className="fa-brands fa-tumblr"></i></a>
                </div>
            </div>
            

        </nav>
    );
}
