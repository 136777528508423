import Footer from "./footer";
import NavBar from "./navBar";
//one
import windmill from "./img/one/windmill.png";
import p38      from "./img/one/p38.png";
import shrooms from "./img/one/mushrooms.png"
//env
import cafe0    from "./img/env/cafe-0.jpg";
import cafe1    from "./img/env/cafe-1.jpg";
import reunion0 from "./img/env/reunion-tower-0.png";
import trees    from "./img/one/trees.png";
import trine    from "./img/env/trine-jungle-recreation.png"
//mat
import bamboo   from "./img/mat/bamboo-screen.png";
import sciTrim  from "./img/mat/sci-trim.png"
//sa
import triShip  from "./img/sa/tri-speeder.png"
import Card from "./card";

function ModelDisplay(props){
    //Title
    //embed sketchfab
    //brief description or noted details
    //Additional Pictures
    return(
        <div className="section">
            <h1>{props.title}</h1>
            <div className="horizontal">
                {props.embed}
                <div className="container">
                    <h3>Description</h3>
                    <p>{props.description}</p>
                    <div style={{height: '50%', width: '100%'}} className="horizontal-scroll">{props.pictures}</div>
                </div>
            </div>
            
        </div>
    );
}

function Categories(){
    const buttonStyle = {margin: '1%'}
return(
    <div className="section">
        <div className="horizontal">
            
            <a style={buttonStyle}><Card title='Hard Surface' item={
                    <img src={triShip} className='thumbnail' alt=""/>
            }
            /></a>
            <a style={buttonStyle}><Card title='Environment' item={
                <img src={cafe0} className='thumbnail' alt="" />
            }
            /></a>
            <a style={buttonStyle}><Card title='Material/Other' item={
                    <img src={sciTrim} className='thumbnail' alt=""/>
            }
            /></a>
        </div>
    </div>
    );
}

function Warning(){
    return(
    <div className="section">
        <div>
            <h1>Hold up, cowpoke!</h1>
            <p>This section is under construction. Check out the highlights section at the bottom for examples or mosey on over <a target='_blank' rel="noreferrer" href="https://www.artstation.com/connorcoxagd">here</a> to see my Art Station.
            </p>
            
        </div>
    </div>
    );
}

function MediaDisplay(){
    //Title
    //Video or Image
    //Brief description or noted details
    //Additional Pictures
}

function Models(){
    return(
        <div className="section">
            <ModelDisplay 
                title="P38 Pistol - Fully Articulated"
                description="The popular WWII era P38 Pistol, modeled and animated to show it's full articulation. Modeled with great accuracy using great images and cleaning videos as reference."
                embed={
                    <div className="sketchfab-embed-wrapper"> 
                        <iframe className="sketchfab" title="P38 Pistol - Articulated" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/6d24745f10624f8da1f3a8c5db36b62a/embed?ui_theme=dark"> </iframe>
                    </div>}
                pictures={<a href={p38} target='_blank' rel="noreferrer"><img className='thumbnail-wide' src={p38} alt=""/></a>}
            />
            <ModelDisplay 
                title="Ace Perinova: Tri-Speeder"
                description="Ship designed for Ace Perinova. This is intended to be the default ship. It also showcases the standard for quality and design for the other ships."
                embed={
                    <div className="sketchfab-embed-wrapper"> 
                        <iframe className="sketchfab" title="The Ol' Tri-Speeder - Ace Perinova Spaceship" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/993b6ac0f76842038ba68c73eff313ee/embed?ui_theme=dark"> </iframe>
                    </div>}
                pictures={<a href={triShip} target='_blank' rel="noreferrer"><img className='thumbnail-wide' src={triShip} alt=""/></a>}
            />
            <ModelDisplay 
                title="My little slice of Redwood Forest"
                description="A stylized mini environment made with hand painted textures and inspired by the Redwood forests of the US West Coast, and the type of tree just outside my new home."
                embed={
                    <div className="sketchfab-embed-wrapper"> 
                        <iframe className="sketchfab" title="P38 Pistol - Articulated" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/8f8de2907b99466f814ff9b93a0fb355/embed?ui_theme=dark"> </iframe>
                    </div>}
                pictures={<a href={shrooms} target='_blank' rel="noreferrer"><img className='thumbnail-wide' src={shrooms} alt=""/></a>}
            />
        </div>
    );

}

function Highlights(){
    return(
        <div className="panel">
            <h3>Highlights</h3>
            <div style={{ height: '25vh', backgroundColor: '#000000' }} className='horizontal-scroll'>
                <a href={cafe0}     target='_blank' rel="noreferrer"><img className='thumbnail' src={cafe0}     alt=""/></a>
                <a href={cafe1}     target='_blank' rel="noreferrer"><img className='thumbnail' src={cafe1}     alt=""/></a>
                <a href={reunion0}  target='_blank' rel="noreferrer"><img className='thumbnail' src={reunion0}  alt=""/></a>
                <a href={trees}     target='_blank' rel="noreferrer"><img className='thumbnail' src={trees}     alt=""/></a>
                <a href={bamboo}    target='_blank' rel="noreferrer"><img className='thumbnail' src={bamboo}    alt=""/></a>
                <a href={sciTrim}   target='_blank' rel="noreferrer"><img className='thumbnail' src={sciTrim}   alt=""/></a>
                <a href={windmill}  target='_blank' rel="noreferrer"><img className='thumbnail' src={windmill}  alt=""/></a>
                <a href={trine}     target='_blank' rel="noreferrer"><img className='thumbnail' src={trine}     alt=""/></a>
            </div>
        </div>
    );

}



export default function Modeling(){
    return(
        <div>
            <NavBar />
            <Models />
            <Warning />
            <Highlights />
            <Footer />
        </div>
    );
}